import React, { createContext, FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { ProjectContextType, IProject, ISimulator } from '../@types/project';
import dataProject from '../data/project';
import axios from 'axios';

export const ProjectContext = createContext<ProjectContextType | null>(null);
export const API_URL = 'https://yconia.com.mx/simulador-panel/public';

interface Props {
    children: ReactNode;
}

const mergeData = ({projectConfig,extAPI = null} : any) => {
    if(projectConfig.buildings){
        const getStatusCode = (s:any) => {
            switch(s){
                case 0: // sold
                    return "rgba(255, 42, 0, 0.5)";
                case 1: // available
                    return "rgba(0, 195, 88, 0.5)";
                case 2: // separated
                    return "rgba(255, 204, 28, 0.5)";
            }
        }
        projectConfig.buildings[0].levels.map((l:any) => {
            l.level = parseInt(l.level);
            l.availability = 0;
            l.areas.map((a:any, key:any) => {
                const apiArea = {};
                a.status = parseInt(a.status);
                a.coords = a.coords.split(',');
                a.dataAPI = apiArea;
                //console.warn("a.status:",a.status,typeof a.status);
                a.preFillColor = getStatusCode(parseInt(a.status));
                if(a.prototype_code.indexOf("FL-") === -1)
                    a.disabled = parseInt(a.status) !== 1 ? true : false;
                if(!a.disabled && a.prototype_code.indexOf("FL-") === -1)
                    l.availability += 1;
            })
        })
    }
    //console.warn("new projectConfig:",projectConfig);
    return projectConfig
}

const ProjectProvider: FunctionComponent<Props> = ({children}) => {

    const [project, setProject] = useState<IProject>(dataProject);
    const [simulator, setSimulator] = useState<ISimulator>({
        current_building: 0,
        current_level: 0,
        current_area: 0,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const localAPIconfig = {
                    headers: {
                        Authorization: `Bearer 4|oDL1Dv1OojwXstzKfNuDu5JPtTI1gDe1ZY6REsdz`,
                    }
                };
                const externalAPIconfig = {
                    headers: {
                        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjFmZjMxYTMwYWM1NGZmNjE5YjA4ZTZiNjU0YmQyMmNjZWRmMjcyNWI4MWI1NWE5Yzg3NzYxNmQxMTliNzQ4NGRmZTM4N2FkZjQwZmEwOTIzIn0.eyJhdWQiOiIzIiwianRpIjoiMWZmMzFhMzBhYzU0ZmY2MTliMDhlNmI2NTRiZDIyY2NlZGYyNzI1YjgxYjU1YTljODc3NjE2ZDExOWI3NDg0ZGZlMzg3YWRmNDBmYTA5MjMiLCJpYXQiOjE2NjgxMjU0MDksIm5iZiI6MTY2ODEyNTQwOSwiZXhwIjoxODE3OTQzMDA5LCJzdWIiOiI3MCIsInNjb3BlcyI6W119.ep7ItXUTKd2OeTj-6t9C0UN9dwOlUk3mBkYq9NxU3ibFmx39NRA2cDBkmMOGA81iYQTSksEKznrJ952Y14Vt5UC9fGSm8fH6UFRrUx0nP4tGcB398CJ99d0kVo7Y84rj9w0W2vW5-y039ljNDKH1yFtqaUAor_ufvwkQ7hDL8wQHbrT0ia-3rs7ZxSdf2CkDdSvx14y1Svm_gzfy5HFvQFu_3McdjpJKSjeBPvXvhEixCfqwUZY8uRX-l91Da-Q6efx8fh2LnUGOXxZGXYKxSzYUJttbsz7PK0rx7jCYfMGGWpPk0yjV2MufTTCOyWozchK9pQ9k1XDt4GmjY8WdwCcBseH1kkHp-4oH_czpzYB5UFTLxDk3dtWxX9gGmwzyuyZuFXKpzksast2P4H0P8tOoEUzS6ifmv-sjnSD8NPBWvtSbC2dbdCc_4nmSo8IUiVUtzVX4qZ_7NIfxFOLcz2xWEPSwMmahfN7jHkZsf3fsf-RyQgJ9wRpiz9mtDkmWd4OujCNs-FQgzaLrJd6wP1ZVZYbFZzO5UMOqjYSkhJtiV5fjZHr5hXYTASvhzetzHgLniAWMqAxqPZkCC5n9PpctlnjKmFuXFTkAwHtuG-YYihW0bbiXoIzmYlqnmryhKX0a81x4bBizk3s1Hf98ikHyUKxJm3ijIHvffuiFY5Y`,
                    }
                };
                const [response1] = await Promise.all([
                    axios.get(API_URL+'/api/v1/interactive/yconia'),
                    //axios.get('https://api.capisoftware.com.mx/popinvestments/capi-b/public/api/unidad_detalles/1/12',externalAPIconfig)
                ]);

                const localAPI = response1.data;
                //const extAPI = response2.data;
                const projectConfig = {
                    ...dataProject,
                    buildings: localAPI || []
                };
                setProject(mergeData({projectConfig}))
            } catch (err) {
                console.warn("Error:",err);
            }
        }
        fetchData()
    },[]);

    return <ProjectContext.Provider value={{ project, setProject, simulator, setSimulator }}>{children}</ProjectContext.Provider>
}

export default ProjectProvider;