import React,{ useState, useContext, useEffect, useRef } from "react";
import { ProjectContext } from '../../context/projectContext';
import ImageMapper from 'react-img-mapper';
import Layout from "../Layout/Layout";
//import project from '../../data/project'
import './BuildingFloor.scss'
import LevelSelector from "../LevelSelector/LevelSelector";
import AreaCard from "../AreaCard/AreaCard";
import { BuildingLevel, IArea } from "../../@types/project";
import { Button, Row } from "react-bootstrap";
import FloorCard from "../FloorCard/FloorCard";
import ArrowNavigation from "../ArrowNavigation/ArrowNavigation";
import BuildingFloorTable from "../BuildingFloorTable/BuildingFloorTable";
import { Link } from "react-router-dom";

type Level = {
    level: number,
    name: string,
}
const LEVEL_VIEW_MODES = {
    'GRAPHIC': 'graphic',
    'LIST': 'list',
}

const BuildingFloor = () => {

    const context = useContext(ProjectContext);
    const project = context?.project;

    const [building, setBuilding] = useState<any>(null);
    const [levelNumber, setLevelNumber] = useState(0);
    const [level,setLevel] = useState<Level>();
    const [buildingLevel,setBuildingLevel] = useState<BuildingLevel>();
    const [floorImage, setFloorImage] = useState('');
    const [floorImageMap, setFloorImageMap] = useState<any>();
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        footerSize: document.querySelector('footer')?.offsetHeight,
    });
    const [selectedArea, setSelectedArea] = useState<IArea>();
    const [selectedFloorArea, setSelectedFloorArea] = useState<IArea>();
    const [areaLabels,setAreaLabels] = useState([]);
    const buildingFloorRef = useRef<HTMLInputElement | null>(null);

    const [levelViewMode, setLevelViewMode] = useState(LEVEL_VIEW_MODES.GRAPHIC);
    const statusConfig = [
        { id: 0, color: 'rgba(207, 57, 40, 0.75)',    textColor: 'rgba(0,0,0,0.5)',         label: 'Vendido' },
        { id: 1, color: 'rgba(184, 145, 107, 0.15)',  textColor: '#B4C98A',  label: 'Disponible' },
        { id: 2, color: 'rgba(171, 145, 12, 0.5)',    textColor: 'rgba(184, 145, 107, 1)',  label: 'Reservado' },
    ]

    useEffect(() => {
        const defaultBuilding = project?.buildings[0];
        const levels = defaultBuilding?.levels.sort((a, b) => a.level - b.level);
        const initialFloor : BuildingLevel = levels ? levels[0] : {
            id: 0,
            building_id: 0,
            level: 0,
            shortName: '',
            name: '',
            shape: null,
            coords: null,
            level_identifier: '',
            areas: [],
        };
        setBuilding(defaultBuilding);
        context?.setSimulator({...context.simulator, current_building: initialFloor.id})
        changeLevel(initialFloor?.level);

        preloadImages(defaultBuilding);
    },[project?.buildings]);
    const preloadImages = (building:any) => {
        building?.levels.forEach((l:any) => {
            const img = new Image();
            const url = `projects/atiko/floors/img-building-${building?.id}-level-${l.level}.jpg`;
            img.src = url;
        })
    };

    const handleResize = () => {
        setDimensions({
            width: buildingFloorRef.current?.offsetWidth || window.innerWidth,
            height: buildingFloorRef.current?.offsetHeight || window.innerHeight,
            footerSize: document.querySelector('footer')?.offsetHeight,
        });
    }

    const getCenterPoint = (coords : []) => {
        const referenceSize = {
            width: 1280,
            height: 720
        }
        const n = coords.length / 2;
        const { y: scaleY, x: scaleX } = coords.reduce(
        ({ y, x }, val, idx) =>
            !(idx % 2) ? { y, x: x + val / n } : { y: y + val / n, x },
        { y: 0, x: 0 }
        );
        //const realCenter = { x: scaleX, y: scaleY };
        const percCenter = { 
            x: scaleX / referenceSize.width,
            y: scaleY / referenceSize.width
         }
        return { 
            x: dimensions.width * percCenter.x, 
            y: dimensions.width * percCenter.y 
        };
        //return { x: scaleX, y: scaleY };
    }
    const handleSetLabels = () => {
        setAreaLabels(buildingLevel?.areas.map((a : IArea) => ({title: `${a.prototype.name} ${a.area}`, center: getCenterPoint(a.coords), status: a.status})))
    }
    useEffect(() => {
        handleSetLabels();
    },[buildingLevel,dimensions]);


    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize, false);
    }, []);

    const loadValidation = setTimeout(() => {
        const mapArea = document.querySelector('.img-mapper-map');
        const level = context?.project.buildings[0]?.levels.find((l: any) => l.level === levelNumber);
        //if(mapArea?.innerHTML.indexOf("Infinity") !== -1 || (!mapArea?.innerHTML.length && level?.areas.length)){
        if(mapArea?.innerHTML.indexOf("Infinity") !== -1){
            remap(levelNumber)
        }
    },1000);
    const remap = (levelNumber : number) => {
        if(!building) return
        const url = `projects/atiko/floors/img-building-${building?.id}-level-${levelNumber}.jpg`;
        const level = context?.project.buildings[0]?.levels.find((l: any) => l.level === levelNumber);
        setBuildingLevel(level);
        setFloorImage(url);
    }
    
    useEffect(() => {
        remap(levelNumber);       
    },[levelNumber, building]);
    useEffect(() => {
        setTimeout(() => {
            if(!context?.project.buildings || !context?.project.buildings.length) return 
            const level = context?.project.buildings[0].levels.find((l: any) => l.level === levelNumber);
            setFloorImageMap({
                name: 'my-map',
                areas: level?.areas || [{ shape: '', coords: [] }]
            });
        },250)
    },[floorImage, building])

    const changeLevel = (l: number) => {
        setLevelNumber(l);
        const newLevel = building?.levels.find((i : any) => i.level===l);
        setLevel(newLevel || null);
        context?.setSimulator({... context?.simulator, current_level: l});
    }

    const handleClickArea = (area : any) => {
        if(area.prototype_code.indexOf("FL-") === -1)
            if(parseInt(area?.status) !== 1) return;

        context?.setSimulator({...context?.simulator, current_area: area.id})
        if(area.prototype_code.indexOf("FL-") > -1)
            setSelectedFloorArea(area);
        else
            setSelectedArea(area);
    }

    const onModalClose = () => setSelectedArea(undefined);
    const onFloorModalClose = () => setSelectedFloorArea(undefined);

    return (
        <Layout style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}>
            <>
                { selectedFloorArea &&
                    <FloorCard area={selectedFloorArea} handleClose={onFloorModalClose} />
                }
                { selectedArea &&
                    <AreaCard area={selectedArea} handleClose={onModalClose} />
                }
                <div className="building-floor" style={{ height: `calc(100vh - ${dimensions.footerSize}px)`, backgroundColor: 'rgba(255, 255, 255, 1)' }} ref={buildingFloorRef}>
                    <Link to={'/home'} className="btn btn-primary building-floor--home-btn">Regresar</Link>
                    <div className="building-floor--info">
                        <span className={`${level?.level === 0 ? 'text-alt' : 'text-primary'}`}>{level?.name}</span>
                    </div>
                    <Row className="building-floor--table">
                        <BuildingFloorTable 
                        building={building} 
                        level={buildingLevel}
                        statusConfig={statusConfig}
                        handleSelectedArea={handleClickArea}  />
                    </Row>
                    <span className="map-north"></span>
                    <ImageMapper 
                        src={floorImage} 
                        map={floorImageMap} 
                        width={1280} 
                        height={720}
                        fillColor='rgba(0, 255, 0, 0.35)'
                        responsive={true} 
                        parentWidth={dimensions.width}
                        onClick={area => handleClickArea(area)} />
                    <div className="map-labels">
                        { areaLabels?.length && areaLabels?.map((area : IArea) => 
                            (
                                <div
                                    key={area.id}
                                    className="map-tooltip"
                                    style={{
                                        position: "absolute",
                                        top: area?.center?.y,
                                        left: area?.center?.x,
                                        zIndex: 1000
                                    }}
                                >
                                    <div>
                                        <span className={`status--${area.status}`}></span>
                                    </div>
                                    <div>{area.title}</div>
                                    <div className="status-label">
                                        {statusConfig.find(s => s.id === area.status)?.label}
                                    </div>
                                </div>
                            )
                        )}
                    </div> 
                    <ArrowNavigation onChangeLevel={changeLevel} />
                </div>
               <LevelSelector onChangeLevel={changeLevel} style={{ zIndex: (selectedArea || selectedFloorArea) ? '3' : '100000' }} />
            </>
        </Layout>
    )
}

export default BuildingFloor;