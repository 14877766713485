//console.warn("process.env.NODE_ENV:",process.env.NODE_ENV);
const BASE_URL = process.env.NODE_ENV === 'production' ? "./" : "./";
const PROJECTS_DIR = BASE_URL+"projects/";
const PROJECT_DIR = PROJECTS_DIR+"atiko/";
export const LOCAL_API_BASE = 'https://yconia.com.mx/simulador-panel/public/api/v1';
const project = {
    id: 1,
    name: "Yconia",
    description: "Demo project",
    status: true,
    styles: {
        colors: {
            primary: "#FF2A000",
        },
        intro : {
            backgroundColor: "rgba(178, 247, 237, 0.75)",
            logo: PROJECT_DIR + "intro-logo.svg",
        }
    },
    assets : {
        masterplan : {
            transitionIn : PROJECT_DIR + "transitions/D-A.mp4", 
        },
        spinner : {
            sides : [
                {   id: 2,
                    endFrame: PROJECT_DIR + 'spinner/B.jpg',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/B-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/B-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/B-trans-overhead.mp4'
                    }
                },
                {   id: 3,
                    endFrame: PROJECT_DIR + 'spinner/C.jpg',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/C-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/C-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/C-trans-overhead.mp4'
                    }
                },
                {   id: 1,
                    endFrame: PROJECT_DIR + 'spinner/A.jpg',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/A-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/A-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/A-trans-overhead.mp4'
                    }
                },
            ]
        },
        galleries: [
          {
            code: 'FL1', 
            prototypes: ['1FL-1'],
            images: [
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-a_atiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-a_atiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-b_atiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-b_atiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-c_atiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-c_atiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-a_atiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-a_atiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-b_atiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-b_atiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-c_atiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-c_atiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_calle_atiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_calle_atiko.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'FL28', 
            prototypes: ['1FL-28'],
            images: [
              {
                url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-1.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-1.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-2.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-2.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-3.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-3.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-4.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-4.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-5.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-5.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G1', 
            prototypes: ['1A1','1A2','1D1','1D2','1D3','1D4'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-A/img-depa-atiko-a-1.jpg',
                original: PROJECT_DIR + 'galleries/model-A/img-depa-atiko-a-1.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G2', 
            prototypes: ['1B1','1B2','1B3','1E1','1E2'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-1.jpg',
                original: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-1.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-2.jpg',
                original: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-2.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G3', 
            prototypes: ['1C1','1C2','1C3'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-C/img-depa-atiko-c-1.jpg',
                original: PROJECT_DIR + 'galleries/model-C/img-depa-atiko-c-1.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
        ],
        gallery: [
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-1.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-1.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-2.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-2.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-3.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-3.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-4.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-4.jpg',
              width: 320,
              height: 174,
            },
        ],
        views: [
          { name: 'macroplaza', prototypes: ['1C1','1C2','1C3','1E1','1E2'], asset: PROJECT_DIR + 'views/macroplaza.jpg' },
          { name: 'santalucia', prototypes: ['1A1','1A2','1B1','1B2','1B3','1D1','1D2','1D3','1D4'], asset: PROJECT_DIR + 'views/santalucia.jpg' },
        ]
    },
    buildings : []
} 
export default project;