import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ProjectContext } from '../../context/projectContext';
import './Header.scss';

const Header = () => {
    const context = useContext(ProjectContext);

    return (
        <header>
            <Link to={'/home'}><span className="home--logo" style={{ backgroundImage: `url(${ context?.project.styles.intro ? context?.project.styles.intro.logo : '' })` }}></span></Link>
        </header>
    )
}

export default Header;